import { render, staticRenderFns } from "./log.vue?vue&type=template&id=5f87fcbf&scoped=true&"
import script from "./log.vue?vue&type=script&lang=js&"
export * from "./log.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f87fcbf",
  null
  
)

export default component.exports